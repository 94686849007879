import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const useAnalytics = () => {
    const location = useLocation();
    // console.log(location);
    
    useEffect(() => {
      // Initialize Google Analytics with your tracking ID
      ReactGA.initialize('G-TY14F4ESF4'); // Replace with your GA tracking ID
  
      // Track initial page load
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, []);
    useEffect(() => {
        // Track page view whenever the location changes
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
      }, [location]); // Depend on location chang
  }

  export default useAnalytics;